
import Vue from "vue";
import TermsAndConditionModal from "@/components/Modals/TermsAndConditionModal.vue";

export default Vue.extend({
  name: "SocialLogin",
  components: { TermsAndConditionModal },
  props: {
    classes: String
  },
  data() {
    return {
      socialIcons: [
        { name: "google", link: "", color: "#1DA1F2" },
        { name: "facebook", link: "", color: "#1DA1F2" }
        // { name: "linkedin", link: "", color: "#1DA1F2" }
      ],
      selectedProvider: ""
    };
  },
  methods: {
    doSocialLogin() {
      const provider = this.selectedProvider;
      if (!provider) {
        return;
      }
      this.$bvModal.hide("terms-and-condition");
      (this as any).$auth
        .authenticate(provider)
        .then((response: unknown) => {
          response = this.updateResponse(response);

          this.$store
            .dispatch("auth/SOCIAL_LOGIN", { provider, response })
            .then(() => {
              (this as any).$alertify.success(
                `Login Successfully Using ${provider.toLocaleUpperCase()}`
              );
              this.$router.push("/discover/mentorcasts");
            })
            .catch((error: any) => {
              if (error.response.status === 422) {
                // @ts-ignore
                this.$alertify.error(error.response.data.errors[0]);
              }
            });
        })
        .catch((error: any) => {
          // @ts-ignore
          this.$alertify.error(error.response.data.message);
        });
    },
    updateResponse(response: any) {
      response.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return response;
    },
    onSocialLogin(provider: string) {
      this.selectedProvider = provider;
      this.$bvModal.show("terms-and-condition");
    }
  }
});
