
import Vue from "vue";
import SocialLogins from "@/components/Partials/SocialLogins.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LogInForm",
  components: {
    SocialLogins
  },
  data() {
    return {
      passwordFieldType: "password",
      data: {
        email: "",
        password: ""
      },
      loginBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      homepageBgPrimary: "helper/getHomePageBgPrimaryStyle"
    })
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onLogin() {
      if (this.loginBtnLoading) {
        return;
      }
      if (this.data.email === "" || this.data.password === "") return;

      this.loginBtnLoading = true;
      this.$store
        .dispatch("auth/LOGIN_USER", this.data)
        .then(() => {
          this.loginBtnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("landingPage.loginFormLoginSuccessMessage")
          );

          this.$router.push("/profile");
        })
        .catch(error => {
          this.loginBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);

              // If email is not registered
              // this.$router.push({
              //   name: "SignUpPage",
              //   params: { step: "email-not-registered" }
              // });
            }
          }
        });
    }
  }
});
